@tailwind base;
@tailwind components;
@tailwind utilities;


/* Temporary workaround to get the Frutiger font added to the project  */
/* Because each weight is added separately, we may lack all Tailwind weight styles */

@font-face {
  font-family: 'Frutiger';
  src: url('https://design-system.digital.nhs.uk/cdn/v0.166.0/fonts/FrutigerLTW01-55Roman.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger';
  src: url('https://design-system.digital.nhs.uk/cdn/v0.166.0/fonts/FrutigerLTW01-65Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger';
  src: url('https://design-system.digital.nhs.uk/cdn/v0.166.0/fonts/FrutigerLTW01-45Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

body {
  font-family: 'Frutiger', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.react-headless-notifier-fixed {
  z-index: 999;
}

